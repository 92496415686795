var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Type")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":"required","name":"Type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"placeholder":"Enter Type"},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("For")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":"required","name":"For"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","options":['Channel Partner', 'Employee'],"placeholder":"None"},model:{value:(_vm.for_type),callback:function ($$v) {_vm.for_type=$$v},expression:"for_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('table',{staticClass:"table table-bordered mt-3"},[_c('thead',{staticClass:"text"},[_c('tr',[(_vm.for_type == 'Channel Partner')?_c('th',[_vm._v(" Rank"),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")])]):_vm._e(),_c('th',[_vm._v("Name")]),(_vm.for_type == 'Channel Partner')?_c('th',[_vm._v(" From"),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")])]):_vm._e(),(_vm.for_type == 'Channel Partner')?_c('th',[_vm._v(" To"),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")])]):_vm._e(),(_vm.for_type == 'Channel Partner')?_c('th',[_vm._v("Days")]):_vm._e(),_c('th',[_vm._v("Incentive"),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")])]),(_vm.for_type == 'Channel Partner')?_c('th',[_vm._v("Delete")]):_vm._e()])]),_c('tbody',_vm._l((_vm.data),function(item,id){return _c('tr',{key:id},[(_vm.for_type == 'Channel Partner')?_c('td',[_c('validation-provider',{attrs:{"rules":"required","name":"Rank"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Enter Rank"},model:{value:(item.rank),callback:function ($$v) {_vm.$set(item, "rank", $$v)},expression:"item.rank"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),_c('td',[_c('b-form-input',{attrs:{"placeholder":"Enter Name"},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}})],1),(_vm.for_type == 'Channel Partner')?_c('td',[_c('validation-provider',{attrs:{"rules":"required","name":"From"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","placeholder":"Enter From"},model:{value:(item.from),callback:function ($$v) {_vm.$set(item, "from", $$v)},expression:"item.from"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.for_type == 'Channel Partner')?_c('td',[_c('validation-provider',{attrs:{"rules":"required","name":"To"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","placeholder":"Enter To"},model:{value:(item.to),callback:function ($$v) {_vm.$set(item, "to", $$v)},expression:"item.to"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.for_type == 'Channel Partner')?_c('td',[_c('b-form-input',{attrs:{"type":"number","placeholder":"Enter Days"},model:{value:(item.days),callback:function ($$v) {_vm.$set(item, "days", $$v)},expression:"item.days"}})],1):_vm._e(),_c('td',[_c('validation-provider',{attrs:{"rules":"required","name":"Incentive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","placeholder":"Enter Incentive "},model:{value:(item.incentive),callback:function ($$v) {_vm.$set(item, "incentive", $$v)},expression:"item.incentive"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(_vm.for_type == 'Channel Partner')?_c('td',[_c('b-button',{attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.removeRow(id)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}})],1)],1):_vm._e()])}),0)]),(_vm.for_type == 'Channel Partner')?_c('b-button',{staticClass:"my-1",attrs:{"variant":"primary"},on:{"click":_vm.addRow}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon"}}),_c('span',[_vm._v("Add More")])],1):_vm._e()],1),_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mr-4",attrs:{"variant":"primary","type":"submit","disabled":_vm.flag},on:{"click":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_vm._v(" Submit ")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.onClickBack}},[_vm._v("Back ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }